import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {BrowserRouter as Router} from 'react-router-dom'
import App from './App';
import { store } from './Store'; 
import {Provider} from 'react-redux'

import reportWebVitals from './reportWebVitals';
import './index.css';
import axios from 'axios';
import { GoogleOAuthProvider } from '@react-oauth/google';


const token = localStorage.getItem('token')
// const token = JSON.parse(localStorage.getItem('token'))
// const token = JSON.parse(localStorage.getItem('token'))
console.log(token)

// const email = localStorage.getItem('email')
// console.log(email)



if(token){
  store.dispatch({type:'LOGIN_SUCCESS', payload:token})
  console.log("in",token)

}

// else if(email){
//   store.dispatch({type:'LOGIN_SUCCESS', payload: email})
//   console.log("in", email)
// }

else{
  console.log("out")

}




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <GoogleOAuthProvider clientId="727287200108-lhblnmhm4vrskc4929aabclf4rv804a1.apps.googleusercontent.com">
    <Provider store={store}>
    <App />
    </Provider>
    </GoogleOAuthProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
