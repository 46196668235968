import "./App.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Home from "./components/Home";
import Auth from "./components/Auth/Login";
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import AllPolls from "./components/All Polls/AllPolls";
import Layout from "./components/Navbar/Layout";
import ViewInsights from "./components/ViewInsights/ViewInsights";
import CreatePoll from "./components/Create_Poll/CreatePoll";
import { useEffect, useState } from "react";
import AllQuizes from "./components/AllQuizes/AllQuizes";
import QuizSettings from "./components/Settings/QuizSettings";
import axios from "axios";
import { useDispatch } from "react-redux";
import { logout } from "./actions/auth";
import Leaderboard from "./components/Leaderboard/Leaderboard";
import { GoogleLogin } from "@react-oauth/google";
import CreateBatch from "./components/Create_Batch/CreateBatch";
import SubCategory from "./components/SubCategory/SubCategory";

function App() {
  const location = useLocation();
  const [pollName, setPollName] = useState('')

  const isAuthRoute = location.pathname === "/login";

  console.log("Token")

  const token = localStorage.getItem('token')
  // const email = localStorage.getItem('email')
  console.log(token)


  const handlePollTitle = (title) => {
    console.log(title)
    setPollName(title)
  }



  const isAuthenticated = localStorage.getItem('token') !== null
  // const isAuthenticated = JSON.parse(localStorage.getItem('token')) !== null
  // console.log(isAuthRoute)
  return (
    <div>
      {!isAuthRoute && <Layout getPollTitle={pollName} />}

      {/* <GoogleLogin
              onSuccess={credentialResponse => {
                console.log(credentialResponse);
              }}
            
              onError={() => {
                console.log('Login Failed');
              }}
              useOneTap
            /> */}

      <Routes>
        <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Auth type="login" />} />
        {/* <Route path="/login" element={<Auth type="login" />} /> */}
        {/* <Route element={<Layout/>}> */}
        <Route path="/" element={isAuthenticated ? <Home pollName={pollName} /> : <Navigate to="/login" />} />
        <Route path="/polls" element={<AllPolls />} />
        <Route path="/all-quizes" element={<AllQuizes />} />
        <Route path="/quiz-settings" element={<QuizSettings />} />
        <Route path="/create-poll" element={<CreatePoll handlePollTitle={handlePollTitle} />} />
        <Route path="/create-batch" element={<CreateBatch />} />
        <Route path="/view-insights" element={<ViewInsights />} />
        <Route path="/subcategory" element={<SubCategory />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        {/* </Route> */}
      </Routes>
    </div>
  );
}

export default App;
