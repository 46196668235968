const initialState = {
    token: localStorage.getItem('token') || null,
    isAuthenticated: false
}

const authReducer = (state = initialState, action) => {

    const { type, payload } = action

    switch (type) {
        case 'LOGIN_SUCCESS':

            // localStorage.setItem('token', payload.token)
            return {
                ...state,
                token: payload,
                isAuthenticated: true
            };
                // localStorage.setItem('token', JSON.stringify(payload))
            // if (payload?.token) {
            //     return {
            //         ...state,
            //         token: payload.token,
            //         isAuthenticated: true
            //     };
            // } 
            // else if (payload?.email) {
            //     return {
            //         ...state,
            //         email: payload.email,
            //         isAuthenticated: true

            //     }
            // }
            // return state;

        case 'LOGOUT':
            localStorage.removeItem('token')
            // localStorage.removeItem('email')
            return {
                ...state,
                token: null,
                isAuthenticated: false
            }
        default:
            return state;
    }
}

export default authReducer