import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  sendDataOtions,
  sendThemeData,
  sentDataApi,
} from "../../actions/create";
import { addNewTheme } from "../Services/theme.service";

const QuizModal = ({ isOpen, onClose, selected, pollAllData, pollData }) => {
  const tagSuggestions = [
    "poll Questions",
    "Simple",
    "Multiple",
    "Dropdown",
    "Yes/No",
  ];

  const [showDialogBox, setShowDialogBox] = useState(false);
  const [showPollNaming, setShowPollNaming] = useState(true);
  const [pollName, setPollName] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [currentTheme, setCurrentTheme] = useState([]);

  const [modalFormData, setModalFormData] = useState([
    {
      pollName: "",
      selectedFiles: null,
      tag: "",
    },
  ]);

  const [options, setOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [displayMessageOnSizeFile, setDisplayMessageOnSizeFile] = useState("");
  const [dataSuccess, setDataSuccess] = useState(false);

  const dispatch = useDispatch();

  const sendData = () => {
    dispatch(sendDataOtions(selectedOption));
    dispatch(sendThemeData(modalFormData.pollName));
    dispatch(sentDataApi(currentTheme));
    console.log("Selected Option", selectedOption);
    console.log("Select Theme", modalFormData.pollName);
    console.log("Select Theme", currentTheme);
  };

  console.log(pollData);
  const handleInputChange = (event) => {
    const { value, files, name } = event.target;

    if (name === "selectedFiles" && files && files[0]) {
      const maxFile = 20 * 1024;
      const file = files[0];
      if (file.size >= maxFile) {
        setDisplayMessageOnSizeFile("*File size must be less than 20 kb*");
        event.target.value = "";
        return;
      } else {
        setDisplayMessageOnSizeFile("");
      }
    }
    setModalFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
    console.log(event);
  };
  console.log(modalFormData.tag);

  const handleClickOnContinue = async () => {
    setIsLoading(true);

    try {
      const token = localStorage.getItem("token");
      // const token = JSON.parse(localStorage.getItem("token"));

      const formData = new FormData();
      formData.append("name", modalFormData.pollName);
      formData.append("image", modalFormData.selectedFiles);
      formData.append("subcategoryData", JSON.stringify(tags));

      await addNewTheme(formData)
        //   await axios
        //     .post(`https://qzpe-stag-panel.pollpe.com/api/v3/theme/add`, formData, {
        //       headers: {
        //         "Content-Type": "multipart/form-data",
        //         Authorization: "Bearer " + token,
        //       },
        //     })
        .then((response) => {
          console.log("Success", response.data);
          setCurrentTheme(response.data);
          const optionsName = response.data.data.subcategory;
          const selectOption = optionsName.map((item) => ({
            value: item.name,
            label: item.name,
            id: item,
          }));
          console.log(optionsName);
          console.log(selectOption);
          setDataSuccess(true);
          setIsLoading(false);
          setOptions(selectOption);
          pollAllData();
        });
    } catch (error) {
      console.log("Network error", error);
      setIsLoading(false);
    }
  };

  const handleSelectOptions = (gettingOption) => {
    setSelectedOption(gettingOption);
    console.log(gettingOption);
  };

  const handleThemeName = (event) => {
    setSelectedTheme(event.target.value);
  };

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "black",
      backgroundColor: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
  };

  const handleDialogBox = () => {
    setShowDialogBox(true);
    console.log("click");
    setShowPollNaming(false);
    setPollName(pollName);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  // //Add Tags
  const addTags = (event) => {
    if (event.target.value !== "") {
      setTags([...tags, event.target.value]);
      selected([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  console.log(tags);

  //Remove Tags
  const removeTags = (indexTo) => {
    setTags(tags.filter((_, index) => index !== indexTo));
    selected(tags.filter((_, index) => index !== indexTo));
  };

  return (
    <div id="create-poll-modal">
      <div
        className={`modal modal-create-poll ${isOpen ? "show" : ""} fade`}
        id="createPoll"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="createPollLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          {showPollNaming && (
            <div
              className={`modal-content ${
                showPollNaming ? "poll-naming" : "hidden"
              }`}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="createPollLabel">
                  Create a new <span>Theme</span>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                  aria-label="Close"
                ></button>
              </div>
              {dataSuccess ? (
                <div id="selectQuizpe" className="modal-body">
                  <div className="first-field mb-4">
                    <label htmlFor="poll-name" className="form-label">
                      Theme
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="pollName"
                        value={modalFormData.pollName}
                        onChange={handleThemeName} //should be handleInput Change if we want to change something
                        aria-label="Poll name"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex-icon gap-4">
                      <div className="first-field mb-4">
                        <label className="form-label">SubCategory</label>
                        <div className="form-control">
                          <Select
                            className="select-component"
                            myFontSize="20px"
                            options={options}
                            styles={styles}
                            onChange={handleSelectOptions}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <NavLink className="modal-footer" to="/create-poll">
                    <button
                      type="submit"
                      className="create-poll-btn"
                      id="poll-naming-continue"
                      onClick={sendData}
                    >
                      Add Question
                    </button>
                  </NavLink>
                </div>
              ) : (
                <div>
                  <div className="modal-body">
                    <div className="first-field mb-4">
                      <label htmlFor="poll-name" className="form-label">
                        Give it a Theme Name
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Theme Name"
                          name="pollName"
                          value={modalFormData.pollName}
                          onChange={handleInputChange}
                          aria-label="Poll name"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex-icon gap-4">
                      <div className="first-field mb-4">
                        <label htmlFor="poll-name" className="form-label">
                          Select the Theme Icon
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type="file"
                            className="form-control"
                            placeholder="Theme Icon"
                            accept="image/png"
                            name="selectedFiles"
                            onChange={handleInputChange}
                            aria-label="Poll name"
                            required
                          />
                        </div>
                        <div className="display-message-on-filesize">
                          {displayMessageOnSizeFile}
                        </div>
                      </div>
                      <div>
                        {selectedFile && (
                          <img
                            src={imagePreview}
                            alt="Icon"
                            className="file-theme"
                          ></img>
                        )}
                      </div>
                    </div>
                    <div className="second-field">
                      <label htmlFor="poll-category" className="form-label">
                        Create SubCategories
                      </label>
                      <div className="category-box d-flex align-items-center mb-3">
                        {tags.map((tag, index) => {
                          return (
                            <div key={index} className="category-name">
                              <span>{tag}</span>
                              <div
                                className="cross-icon d-flex align-items-center justify-content-center"
                                onClick={() => removeTags(index)}
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </div>
                            </div>
                          );
                        })}

                        <div className="add-more-btn d-flex align-items-center gap-2">
                          <input
                            type="text"
                            list="tagSuggestions"
                            placeholder="Press Enter the SubCategory"
                            name="tag"
                            value={modalFormData.tag}
                            // onChange={handleInputChange}
                            onKeyUp={(e) =>
                              e.key === `Enter` ? addTags(e) : null
                            }
                          />
                          <datalist id="tagSuggestions">
                            {tagSuggestions.map((tag, index) => (
                              <option
                                key={index}
                                onKeyUp={(e) =>
                                  e.key === `Enter` ? addTags(e) : null
                                }
                                value={tag}
                              />
                            ))}
                          </datalist>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer" to="/create-poll">
                    {isLoading ? (
                      <span className="loader"></span>
                    ) : (
                      <button
                        type="submit"
                        className="create-poll-btn"
                        id="poll-naming-continue"
                        onClick={handleClickOnContinue}
                      >
                        Continue
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* <!-- Poll Type Selection on Continue --> */}
          {showDialogBox && (
            <div
              className={`modal-content ${
                showDialogBox ? "poll-selection" : "hidden"
              }`}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="createPollLabel">
                  Select the <span>Subcategory</span>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                  aria-label="Close"
                ></button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizModal;
