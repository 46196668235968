import React, { useEffect, useState } from "react";
import Loader from "../../Loader/Loader";
import { details } from "../Services/quiz.service";
import Pagination from "../Pagination/Pagination";
import { data } from "jquery";

const Leaderboard = () => {
  const [loader, setLoader] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);
  const [length, setLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchBar, setSearchBar] = useState(false);
  const [filteredData, setFilteredData] = useState(leaderboard);
  const [dateFormat, setDateFormat] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [dataNotAvailable, setDataNotAvailable] = useState("");
  // console.log(currentPage);
  const rewardValues = [1, 2, 3];

  //Filtered Data with theme and sub Category

  useEffect(() => {
    const filteredResult = leaderboard.filter((item) => {
      const itemString = `${item.themeName} ${item.subCategoryName} ${item.date}`;
      return (
        itemString.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.subCategoryName
          .toLowerCase()
          .includes(
            searchQuery.toLowerCase() ||
              item.date.toString().includes(searchQuery.trim())
          )
      );
      // return itemString.themeName.toLowerCase().includes(searchQuery.toLowerCase())
      // const themeMatch =
      //   themeNameQuery.trim() === "" ||
      //   item.themeName.toLowerCase().includes(themeNameQuery.toLowerCase());

      // const subCategoryMatch =
      //   subCategoryQuery.trim() === "" ||
      //   item.subCategoryName.toLowerCase().includes(subCategoryQuery.toLowerCase());
      // const dateMatch =
      //   dateQuery.trim() === "" ||
      //   item.date.toString().includes(dateQuery.trim());

      //   return themeMatch && subCategoryMatch && dateMatch
    });

    setFilteredData(filteredResult);
    setLength(filteredData.length);
  }, [searchQuery, leaderboard, filteredData.length]);

  console.log(filteredData);

  //Pagination

  const itemsPerPage = 10;

  // useEffect(()=>{
  const handlePerPage = (newPage) => {
    // console.log(newPage);
    // console.log("clicked NewPage");
    // if (newPage >= 1 && newPage <= Math.ceil(length / itemsPerPage)) {
    setCurrentPage(newPage);
    // }
  };

  console.log(dateFormat);

  // },[])

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoader(false);
  //   }, 3000);
  // }, []);

  useEffect(() => {
    const leaderboardData = async () => {
      setLoader(true);
      try {
        const res = await details(dateFormat);

        if (res.status === 200) {
          console.log(res.data.data.leaderBoard);
          // console.log(res.data.data.leaderBoard[0]);
          // setLength(res.data.data.leaderBoard.length);
          setLeaderboard(res.data.data.leaderBoard);
          setLoader(false);
          if (length === 0 || length === undefined || length === null) {
            setDataNotAvailable(
              "Data is not available of this particular Date"
            );
          } else {
            setDataNotAvailable("");
          }
        } else {
          console.log("error in Leaderboard");
        }
      } catch (error) {
        console.log(error);
        setLoader(false);
      }
    };
    leaderboardData();
  }, [dateFormat, length]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setDateFormat(newDate);
  };

  // console.log(length);
  // console.log(currentData);

  console.log(loader);

  return (
    <div>
      {loader && <Loader />}

      <main id="main" className="main">
        <section className="section dashboard create-poll-page">
          {/* Low Performer */}
          <div className="col-12 mt-5">
            <div className="card trending-polls overflow-auto">
              <div className="card-body">
                <div className="card-title-header d-flex justify-content-between align-items-center">
                  <div className="center-leaderboard-heading">
                    <h2 className="card-title">Top Performer</h2>
                    <div className="date-format">
                      <input
                        className="input-search"
                        type="date"
                        placeholder="Search Theme Name and Sub Category"
                        value={dateFormat}
                        onChange={(e) => handleDateChange(e)}
                      />
                    </div>
                  </div>
                  {/* <a href="/" className="">
                    View All
                  </a> */}
                  <div className="search-box">
                    <input
                      className="input-search"
                      type="search"
                      placeholder="Search Theme Name and Sub Category"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
                {dataNotAvailable !== "" && (
                  <span className="text-danger fs-5 ">
                    *{dataNotAvailable}*
                  </span>
                )}

                {/* <div className="table-responsive"> */}
                <table className="table table-bordered datatable table-striped table-quiz">
                  <thead className="">
                    <tr className="quiz-thead">
                      <th className="col-1 quiz-thead-text text-center">
                        Rank
                      </th>
                      <th className="col-1 quiz-thead-text text-center">
                        Username
                      </th>
                      <th className="col-1 quiz-thead-text text-center">TCA</th>
                      {/* <th className="col-1 quiz-thead-text text-center">TD</th> */}
                      <th className="col-1 quiz-thead-text text-center">
                        Theme
                      </th>
                      <th className="col-1 quiz-thead-text text-center">
                        SubCategory
                      </th>
                      <th className="col-1 quiz-thead-text text-center">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center quiz-tdata-text">
                          {item.id}
                        </td>
                        <td className="text-center quiz-tdata-text">
                          {item.user.fullName}
                        </td>
                        <td className="text-center quiz-tdata-text">
                          {item.totalCorrectAnswer}
                        </td>
                        {/* <td className="text-center quiz-tdata-text">
                          {item.totalDuration}
                        </td> */}
                        <td className="text-center quiz-tdata-text">
                          {item.themeName}
                        </td>
                        <td className="text-center quiz-tdata-text">
                          {item.subCategoryName}
                        </td>
                        <td className="text-center quiz-tdata-text">
                          {item.date}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex justify-end">
                  {length !== 0 && (
                    <Pagination
                      item={length}
                      itemsPerPage={itemsPerPage}
                      onPageChange={handlePerPage}
                      currentPage={currentPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Leaderboard;
