const initialState = {
    selectedOption: null,
    selectedTheme: '',
    currentTheme: []
}

const createReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SELECTED_OPTION':
            return {
                ...state,
                selectedOption: action.payload,
            };
        case 'SELECTED_THEME':
            return {
                ...state,
                selectedTheme: action.payload
            }
        case 'SELECTED_APIDATA':
            return {
                ...state,
                currentTheme: action.payload
            }
        default:
            return state;
    }

}

export default createReducer