import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  sendDataOtions,
  sendThemeData,
  sentDataApi,
} from "../../actions/create";
import { fetchCategories, getQuizData } from "../Services/quiz.service";

const CreateSubModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const [showPollNaming, setShowPollNaming] = useState(true);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [themeData, setThemeData] = useState([]);
  const [selectedThemeId, setSelectedThemeId] = useState(null);
  const [category, setCategory] = useState("");
  const [themeNameSelect, setThemeNameSelect] = useState([]);
  const [themeName, setThemeName] = useState("");
  const [currentTheme, setCurrentTheme] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const handleSelectOptions = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "black",
      backgroundColor: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
  };

  const sendData = () => {
    setShowLoader(true);
    dispatch(sendDataOtions(selectedOption));
    dispatch(sendThemeData(themeName));
    dispatch(sentDataApi(currentTheme));
    console.log("Selected Option", selectedOption);
    console.log("Select Theme", selectedTheme);
    console.log("Select Theme", currentTheme);
    setTimeout(() => {
      setShowLoader(false);
      handleClose();
    }, 3000);
  };

  // console.log(themeData)
  const token = localStorage.getItem("token");
  // const token = JSON.parse(localStorage.getItem("token"));
  const getData = async () => {
    try {
      const response = await getQuizData();
      if (response.status === 200) {
        console.log(response.data);
        setThemeData(response.data.data.feed);

        const themeData = response.data.data.feed;
        const themeNameSelected = themeData.map((item) => ({
          value: item.name,
          label: item.name,
        }));
        console.log(themeNameSelected);
        setThemeNameSelect(themeNameSelected);
      } else {
        console.log(response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();

    // try {

    //   axios
    //     .get(`https://qzpe-stag-panel.pollpe.com/api/v3/theme/getall`, {
    //       headers: {
    //         Authorization: "Bearer " + token,
    //       },
    //     })

    //     .then((response) => {
    //       console.log(response.data);
    //       setThemeData(response.data.data.feed);
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  }, [token]);
  // }, []);

  //Filter Data

  const handleInputChange = (selectedOption) => {
    // const inputText = event.target.value;
    // setThemeName(inputText);
    setThemeName(selectedOption.label);
    console.log(selectedOption);

    if (selectedOption) {
      const matchingTheme = themeData.find((theme) =>
        theme.name.toLowerCase().includes(selectedOption.value.toLowerCase())
      );
      if (matchingTheme) {
        setSelectedThemeId(matchingTheme.id);
        fetchCategory(matchingTheme.id);
      } else {
        setSelectedThemeId(null);
        setCategory("");
      }
    } else {
      setSelectedThemeId(null);
      setCategory("");
    }
  };

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  console.log(BASE_URL);

  const fetchCategory = async (id) => {
    try {
      const response = await fetchCategories(id);
      console.log(response);
      // axios
      //   .get(`https://qzpe-stag-panel.pollpe.com/api/v3/subcategory/f/${id}`, {
      //     headers: {
      //       Authorization: "Bearer " + token,
      //     },
      //   })
      if (response.status === 200) {
        console.log(response.data);
        console.log(response.data.data.subcategory);
        setCurrentTheme(response.data);
        const optionsName = response.data.data.subcategory;
        const selectOption = optionsName.map((item) => ({
          value: item.name,
          label: item.name,
          id: id,
          themesubCategoryId: item.id,
        }));
        console.log(selectOption);
        setOptions(selectOption);
      }
      else{
        console.log(response.status)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  //   const pollAllData = async () => {
  //     const cancelRequest = false;
  //     setShowLoader(true);
  //     try {
  //       const response = await getQuizData();
  //       if (!cancelRequest) {
  //         setShowLoader(false);
  //         setPollData(response.data.data.feed);
  //         console.log(response.data.data.feed);
  //       }
  //     } catch (error) {
  //       if (!cancelRequest) {
  //         setShowLoader(false);
  //         console.error("Error fetching poll data:", error);
  //       }
  //     }
  //   };

  useEffect(() => {
    // pollAllData()
  }, []);

  return (
    <div id="create-poll-modal">
      <div
        className={`modal modal-create-poll ${isOpen ? "show" : ""} fade`}
        id="createPoll"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="createPollLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          {" "}
          {showPollNaming && (
            <div
              className={`modal-content ${
                showPollNaming ? "poll-naming" : "hidden"
              }`}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="createPollLabel">
                  Select the <span> Theme Name </span>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                  aria-label="Close"
                ></button>
              </div>
              <div id="selectQuizpe" className="modal-body">
                <div className="flex-icon gap-4">
                  <div className="first-field mb-4">
                    <label htmlFor="poll-name" className="form-label">
                      Theme
                    </label>
                    <div className="input-group mb-3">
                      <Select
                        className="select-component"
                        myFontSize="20px"
                        options={themeNameSelect}
                        styles={styles}
                        onChange={handleInputChange}
                      />
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="pollName"
                        value={themeName}
                        onChange={handleInputChange}
                        aria-label="Poll name"
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="first-field mb-4">
                  <label className="form-label"> SubCategory </label>
                  <div className="form-control">
                    <Select
                      className="select-component"
                      myFontSize="20px"
                      options={options}
                      styles={styles}
                      onChange={handleSelectOptions}
                    />
                  </div>
                </div>

                <div className="modal-footer" to="/create-poll">
                  {showLoader ? (
                    <span className="loader"></span>
                  ) : (
                    <button
                      type="submit"
                      className="create-poll-btn"
                      id="poll-naming-continue"
                      onClick={sendData}
                    >
                      Add Question
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateSubModal;
