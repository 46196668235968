export const sendDataOtions = (selectedOption) => ({
    type: 'SELECTED_OPTION',
    payload: selectedOption
})

export const sendThemeData = selectedTheme => ({
    type: 'SELECTED_THEME',
    payload: selectedTheme
})

export const sentDataApi = (currentTheme) => ({
    type: 'SELECTED_APIDATA',
    payload: currentTheme
})