import React, { useState } from "react";

const Pagination = ({ item, itemsPerPage, onPageChange, currentPage }) => {
  // console.log(item)
  // console.log(item.item)
  // console.log(itemsPerPage)
  // console.log(onPageChange)
  // console.log(currentPage)
  const totalPages = Math.ceil(item / itemsPerPage);
  // const startIndex = (currentPage-1) * itemsPerPage
  // const endIndex = startIndex + itemsPerPage
  // let pageNumber = []
  let pageLinksToShow = 5;
  // for(let i = 1; i <= Math.ceil(item / itemsPerPage); i++ ){
  //     pageNumber.push(i)
  // }

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    } else {
      onPageChange(1);
    }
  };

  const renderPageLinks = () => {
    const pageLinks = [];
    let startPage = Math.max(1, currentPage - Math.floor(pageLinksToShow / 2));
    let endPage = Math.min(totalPages, startPage + pageLinksToShow - 1);

    if (endPage - startPage < pageLinksToShow - 1) {
      startPage = Math.max(1, endPage - pageLinksToShow + 1);
    }

    if (startPage > 1) {
      pageLinks.push(
        <li
          className="pagination-number"
          key={1}
          onClick={() => handlePageChange(1)}
        >
          1
        </li>
      );
      if (startPage > 2) {
        pageLinks.push(
          <li key="ellipsis-start" className="pagination-number">
            ...
          </li>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageLinks.push(
        <li
          key={i}
          onClick={() => handlePageChange(i)}
          className={`pagination-number ${i === currentPage ? "active" : ""}`}
        >
          {i}
        </li>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageLinks.push(
          <li key="ellipsis-end" className="pagination-number">
            ...
          </li>
        );
      }
      pageLinks.push(
        <li
          key={totalPages}
          className="pagination-number"
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </li>
      );
    }

    return pageLinks;
  };

  return (
    <div className="pagination-style">
      <button
        className="bn632-hover bn24"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        Pre
      </button>

      {/* {pageNumber.map((number)=>(
            <li key={number} className={`${number === currentPage ? 'active' : ''} `} onClick={() => handlePageChange(number)}>{number}</li>
        ))} */}
      {renderPageLinks()}
      <button
        className="bn632-hover bn24"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
