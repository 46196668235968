import axios from "axios";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import QuestionTextModal from "../UI/QuestionTextModal";
import { deleteSpecificQuestion, fetchQuestionsOfSpecificTheme, getQuizData } from "../Services/quiz.service";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Loader from "../../Loader/Loader";

const AllQuizes = () => {
  const selectedOption = useSelector((state) => state.create.selectedOption);

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [themeData, setThemeData] = useState([]);
  const [activeThemeId, setActiveThemeId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updateQuestionText, setUpdateQuestionText] = useState();
  const [updateQuestionId, setUpdateQuestionId] = useState();
  const [deleteQuestionId, setDeleteQuestionId] = useState(null);
  const [updateQuestionName, setUpdateQuestionName] = useState(" ");
  const [questionList, setQuestionList] = useState();
  // const token = JSON.parse(localStorage.getItem("token"));
  const token = localStorage.getItem("token");

  console.log(selectedOption);

  const getData =async () => {
    try{
      const response = await getQuizData()
      if(response.status === 200){
        console.log(response.data.data.feed);
          setThemeData(response.data.data.feed);

          setLoader(false);
      }
    }catch(error){
      console.log(error)
      setLoader(false);
    }
  }

  useEffect(() => {
    setLoader(true);
    getData()

    // try {
    //   axios
    //     .get(`https://qzpe-stag-panel.pollpe.com/api/v3/theme/getall`, {
    //       headers: {
    //         Authorization: "Bearer " + token,
    //       },
    //     })
    //     .then((res) => {
    //       console.log(res.data.data.feed);
    //       setThemeData(res.data.data.feed);

    //       setLoader(false);
    //     });
    // } catch (error) {
    //   console.log(error);
    //   setLoader(false);
    // }
  }, []);

  // useEffect(()=>{
    // setUpdateQuestionName()
  // },[])

  const handleQuestion = async (id) => {
    setLoader(true);
    setUpdateQuestionId(id);
    setActiveThemeId(id);
    // setUpdateQuestionName(name);
    try {

      await fetchQuestionsOfSpecificTheme(id)
      // axios
      //   .get(`https://qzpe-stag-panel.pollpe.com/api/v3/question/theme/${id}`, {
      //     headers: {
      //       Authorization: "Bearer " + token,
      //     },
      //   })
        .then((res) => {
          console.log(res.data.data.questionsList.length);
          setData(res.data.data);
          setQuestionList(res.data.data.questionsList.length);
          setLoader(false);
        });
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleDeleteQuestion = async () => {
    setLoader(true)
    try {
      await deleteSpecificQuestion(deleteQuestionId)
      // axios
      //   .delete(
      //     `https://qzpe-stag-panel.pollpe.com/api/v3/question/${deleteQuestionId}`,
      //     {
      //       headers: {
      //         Authorization: "Bearer " + token,
      //       },
      //     }
      //   )
        .then((res) => {
          console.log(res.data);
          setLoader(false)
          handleQuestion(activeThemeId);
          setDeleteModal(false);
        });
    } catch (error) {
      console.log(error);
      setLoader(false)
      setDeleteModal(false);
    }
  };

  const subcategoryColors = () => {
    // Add more subcategory IDs and their corresponding colors here
  };

  console.log(updateQuestionId);
  console.log(updateQuestionName);

  const colors = ["black", "#A7E216", "#1486E8", "green", "#8338ec"];
  const colorMap = {};

  const handleUpdate = (
    id,
    options,
    questionText,
    questionDifficulty,
    itemId
  ) => {
    setShowEditModal(true);
    setUpdateQuestionText({
      id,
      options,
      questionText,
      questionDifficulty,
      itemId,
    });
    // try{
    //   axios.put(`https://qzpe-stag-panel.pollpe.com/api/v3/question/${id}`,{
    //     headers: {
    //       "Content-Type" : "application/json",
    //       Authorization: "Bearer " + token
    //     }
    //   })
    //   .then(res=> {console.log(res.data)})
    // }
    // catch(error){
    //   console.log(error)
    // }
  };

  console.log(updateQuestionText);

  const handleCloseUpdateModal = () => {
    setShowEditModal(false);
    setUpdateQuestionText(null);
  };

  // useEffect(() => {
  //   setLoader(true);
  //   try {
  //     axios
  //       .get(
  //         `https://qzpe-stag-panel.pollpe.com/api/v3/question/theme/${
  //           +selectedOption?.id || selectedOption?.id.quizpeThemeId
  //         }`,
  //         {
  //           headers: {
  //             Authorization: "Bearer " + token,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         console.log(res.data.data);
  //         setData(res.data.data);
  //         setLoader(false);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  //   console.log(data.theme.name);
  return (
    <div>
      {showEditModal && (
        <QuestionTextModal
          isOpen={showEditModal}
          onClose={handleCloseUpdateModal}
          handleUpdate={updateQuestionText}
          handleQuestion={handleQuestion}
        />
      )}
      {loader && (
        <Loader></Loader>
      )}
      <main id="main" className="main">
        <div className="theme-question-card">
          {themeData.map((item) => (
            <div className="theme-data-card" key= {item.id}>
              <div className="split-section">
                <div className="sub-theme-data-card">
                  <div className="set-icon-name">
                    <img
                      src={item.icon}
                      className="file-icon"
                      alt={item.name}
                    />
                    <p>{item.name}</p>
                  </div>
                </div>
              </div>
              <div className="view-question-split">
                <div className="view-question">
                  <button
                    onClick={() => {
                      handleQuestion(item.id);
                      setUpdateQuestionName(item.name);
                    }}
                  >
                    View Question
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {activeThemeId === updateQuestionId && (
          <div className="allquizes-maincard mt-5">
            <h2 className="update-question">
              {updateQuestionName} - [{questionList}]
            </h2>
            <div className="allquizes-question">
              {data.questionsList?.map((question, index) => {
                if (!colorMap[question.quizpeSubcategoryId]) {
                  const colorIndex =
                    question.quizpeSubcategoryId % colors.length;
                  colorMap[question.quizpeSubcategoryId] = colors[colorIndex];
                }
                const textColor = colorMap[question.quizpeSubcategoryId];
                return (
                  <div className={`${"horizontal-card"}`} key={question.id}>
                    <div className={`allquizes`}>
                      <div className="flex-id">
                        <p className="question-id">#{question.id}</p>
                        <p
                          className={`quizpe-subcategoryid subcategory-color-${question.quizpeSubcategoryId}`}
                          style={{ backgroundColor: textColor || "white" }}
                        >
                          <span className="question_of_quizpe">
                            {question?.quizpe_subcategory?.name}
                          </span>
                          <span> ({question.quizpeSubcategoryId})</span>
                        </p>
                      </div>
                      <h2>
                        {index + 1}. {question.text}
                      </h2>
                      <div className="allquizes-options">
                        {question.quizpe_options.map((quizpeoptions, index) => (
                          <h4
                            className={`quizpe-options ${
                              quizpeoptions.isCorrectOption
                                ? "quizpe-options-iscorect-option"
                                : ""
                            }`}
                            key={quizpeoptions.id}
                            // style={{
                            //   border: quizpeoptions.isCorrectOption
                            //     ? " 1px solid #c5c4c3"
                            //     : "",
                            //   color: quizpeoptions.isCorrectOption
                            //     ? "#83E233"
                            //     : "",
                            // }}
                          >
                            <div
                              className="handle-option-index input-group"
                              key={index}
                            >
                              <span
                                className="input-group-text"
                                style={{
                                  backgroundColor: quizpeoptions.isCorrectOption
                                    ? "#83E233"
                                    : " ",
                                }}
                              >
                                {/* {choice.id} */}
                                {index + 1}
                              </span>
                              <p className="quizpe-option-text">
                                {quizpeoptions.text}
                              </p>
                            </div>
                          </h4>
                        ))}
                      </div>
                      <hr />
                      <div className="quizpe-operations">
                        <button
                          className="quizpe-edit"
                          onClick={() =>
                            handleUpdate(
                              question.id,
                              question.quizpe_options,
                              question.text,
                              question.difficulty,
                              updateQuestionId
                            )
                          }
                        >
                          Edit
                        </button>
                        <button
                          className="quizpe-delete"
                          onClick={() => {
                            setDeleteModal(true);
                            setDeleteQuestionId(question.id);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
              {deleteModal && (
                <div className="modal-to-delete-item">
                  <div className="modal-delete">
                    <span className="modal-icon">
                      <button onClick={() => setDeleteModal(false)}>
                        <RxCross1 />
                      </button>
                    </span>

                    <div className="all-deletecontent-center">
                      <div className="a-border">
                        <div className="modal-delete-icon">
                          {/* <AiFillDelete /> */}
                          <RxCross1 style={{ fontWeight: 900 }} />
                        </div>
                      </div>
                      <h2>Are you Sure?</h2>
                      <p>
                        Do you really wants to delete the this Question? This
                        process cannot be undone.
                      </p>
                      <hr style={{ backgroundColor: "black" }} />
                      <div className="cancel-delete">
                        <button
                          className="cancel-spec"
                          onClick={() => setDeleteModal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="delete-specific"
                          onClick={handleDeleteQuestion}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default AllQuizes;
