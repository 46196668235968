import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL;
const axiosInstance = axios.create({
    // baseURL: process.env.REACT_APP_BASE_URL,
    baseURL: BASE_URL,
})

// console.log(token)

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token')

        console.log(token)
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        config.headers["Content-Type"] = "application/json"

        return config;
    },
    (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
    (response) => { return response },
    (error) => {
        // const original = error.config;

        if (error.response && error.response.status === 401) {
            // original.retry = true;
            localStorage.removeItem('token')
            window.location.href = '/login'
                // localStorage.removeItem('email')
                // dispatch(logout())
        }
        return Promise.reject(error)
    }
)

const logout = () => {
    localStorage.removeItem('token')
        // localStorage.removeItem('email')
    window.location.href = '/login'
}

export const authapi = (username, password) => {
    try {

        const response = axiosInstance.post(`/oauth2/login`, {
            username: username,
            password: password
        })
        if (response === 401) {
            logout()
            return null
        } else {
            return response
        }
    } catch (error) {
        console.log("Getting error from Auth Service", error);
        throw error;
    }

}