import React, { useState } from "react";
import Loader from "../../Loader/Loader";
import Papa from 'papaparse';
import { FaCheck, FaTimes, FaDownload, FaUpload } from 'react-icons/fa';
import ReactPaginate from "react-paginate";
import { addQuestion } from "../Services/post.service";

const CreateBatch = () => {
  const token = localStorage.getItem("token");

  const [file, setFile] = useState(null);
  const [successfullyAdded, setSuccessfullyAdded] = useState("");
  const [successfullyAddedModal, setSuccessfullyAddedModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [numberofRows, setNumberOfRows] = useState({
    uploaded: 0,
    notUploaded: 0,
  })
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsUploaded, setRowsUploaded] = useState([]);
  const itemsPerPage = 3;

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    Papa.parse(selectedFile, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: (results) => {
        const formattedData = results.data.map((row) => ({
          text: row.text,
          difficulty: row.difficulty,
          quizeThemeId: row.quizeThemeId,
          quizeSubcategoryId: row.quizeSubcategoryId,
          option1: row.option1,
          option2: row.option2,
          option3: row.option3,
          option4: row.option4,
          correctOption: row.correctOption,
        }));
        setData(formattedData);
      },
    });
  };

  const downloadDemoCSV = () => {
    const demoData = [
      {
        "text": "This is a sample text for the quiz.",
        "difficulty": 1,
        "quizeThemeId": 1,
        "quizeSubcategoryId": 1,
        "option1": "Option A",
        "option2": "Option B",
        "option3": "Option C",
        "option4": "Option D",
        "correctOption": 1
      },
      {
        "text": "This is a sample text for the quiz.",
        "difficulty": 1,
        "quizeThemeId": 1,
        "quizeSubcategoryId": 1,
        "option1": "Option A",
        "option2": "Option B",
        "option3": "Option C",
        "option4": "Option D",
        "correctOption": 1
      },
      {
        "text": "This is a sample text for the quiz.",
        "difficulty": 1,
        "quizeThemeId": 1,
        "quizeSubcategoryId": 1,
        "option1": "Option A",
        "option2": "Option B",
        "option3": "Option C",
        "option4": "Option D",
        "correctOption": 1
      },
      // Add more demo data as needed
    ];

    const csvData = Papa.unparse(demoData);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'demo.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Download not supported');
    }
  };

  const downloadNotUploadedCSV = () => {
    const csvData = Papa.unparse({
      fields: [
        'status',
        'text',
        'difficulty',
        'quizeThemeId',
        'quizeSubcategoryId',
        'option1',
        'option2',
        'option3',
        'option4',
        'correctOption',
        'Reason',
      ],
      data: errors.map((row) => {
        const { status, text, difficulty, quizeThemeId, quizeSubcategoryId, option1, option2, option3, option4, correctOption, reason } = row;
        return [
          status,
          text,
          difficulty,
          quizeThemeId,
          quizeSubcategoryId,
          option1,
          option2,
          option3,
          option4,
          correctOption,
          reason,
        ];
      }),
    });

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'invalid_rows.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadUploadedCSV = () => {
    const csvData = Papa.unparse({
      fields: [
        'status',
        'text',
        'difficulty',
        'quizeThemeId',
        'quizeSubcategoryId',
        'option1',
        'option2',
        'option3',
        'option4',
        'correctOption',
        'Reason',
      ],
      data: rowsUploaded.map((row) => {
        const { status, text, difficulty, quizeThemeId, quizeSubcategoryId, option1, option2, option3, option4, correctOption, reason } = row;
        return [
          status,
          text,
          difficulty,
          quizeThemeId,
          quizeSubcategoryId,
          option1,
          option2,
          option3,
          option4,
          correctOption,
          reason,
        ];
      }),
    });

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'uploaded_questions.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = async () => {
    setShowLoader(true);
    const validRows = [];
    const errorRows = [];
    const questionsUploaded = [];

    data.forEach((row, index) => {
      const { text, difficulty, quizeThemeId, quizeSubcategoryId, option1, option2, option3, option4, correctOption } = row;
      if (text &&
        difficulty &&
        quizeThemeId &&
        quizeSubcategoryId &&
        option1 &&
        option2 &&
        option3 &&
        option4 &&
        correctOption) {
        validRows.push({ ...row, status: 'pending', reason: '' });
      }
      else {
        const missingFields = [];
        if (!text) missingFields.push('text');
        if (!difficulty) missingFields.push('difficulty');
        if (!quizeThemeId) missingFields.push('quizeThemeId');
        if (!quizeSubcategoryId) missingFields.push('quizeSubcategoryId');
        if (!option1) missingFields.push('option1');
        if (!option2) missingFields.push('option2');
        if (!option3) missingFields.push('option3');
        if (!option4) missingFields.push('option4');
        if (!correctOption) missingFields.push('correctOption');
        errorRows.push({ ...row, reason: `Missing field: ${missingFields.join(', ')}`, status: 'error' })
      }
    });

    if (validRows.length === 0) {
      setErrors(errorRows);
    } else {
      for (const { index, ...row } of validRows) {
        try {
          const response = await addQuestion({
            "text": row.text,
            "difficulty": row.difficulty,
            "quizpeThemeId": row.quizeThemeId,
            "quizpeSubcategoryId": row.quizeSubcategoryId,
            "optionData": [
              {
                "text": row.option1,
                "isCorrectOption": row.correctOption == 1 ? true : false
              },
              {
                "text": row.option2,
                "isCorrectOption": row.correctOption == 2 ? true : false
              },
              {
                "text": row.option3,
                "isCorrectOption": row.correctOption == 3 ? true : false
              },
              {
                "text": row.option4,
                "isCorrectOption": row.correctOption == 4 ? true : false
              }
            ]
          });
          if (response.status === 200) {
            questionsUploaded.push({ ...row, status: 'success' });
          } else {
            errorRows.push({ ...row, status: 'error', reason: `Error uploading row: ${response}}` });
          }
        } catch (error) {
          console.error('Error uploading row:', error);
          errorRows.push({ ...row, status: 'error', reason: `Error uploading row: ${error}}` });
        }
        finally {
          setErrors(errorRows);
          setNumberOfRows({
            uploaded: questionsUploaded.length,
            notUploaded: errorRows.length,
          })
          setRowsUploaded(questionsUploaded);
          setShowLoader(false);
        }
      }
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const paginatedData = data.slice(offset, offset + itemsPerPage);
  const dataStartCount = offset + 1;
  const dataEndCount = offset + paginatedData.length;



  return (
    <div>
      {showLoader && <Loader />}

      {successfullyAddedModal && (
        <div className="successfully-added">
          <span className="added">{successfullyAdded}</span>
        </div>
      )}
      <main id="main" className="main">
        <section className="section dashboard create-poll-page">
          <div className="tabs">
            <hr className="border my-2" />

            {/* <!-- Tab Content --> */}
            <div className="tab-content">
              <div className="tab-pane active show" id="tab-1">
                {/* <!-- Question Section --> */}

                <div className="question-area w-100 d-flex justify-content-between">
                  {/* <!-- Create Poll Section --> */}

                  <div className="create-poll-mid-container ps-3">
                    <div className="">
                      <div className="row">
                        <div className="my-6 bg-white rounded-md">
                          <div className="p-4">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <label htmlFor="formFile" className="form-label">Choose File</label>
                                <input accept=".csv,.xlsx,.xls" onChange={handleFileUpload} className="form-control" type="file" id="formFile" />
                              </div>
                              <div className="d-flex gap-4 align-items-center">
                                <button className="create-poll-btn" onClick={downloadDemoCSV}><FaDownload /> Download Demo CSV</button>
                                {
                                  data.length > 0 &&
                                  <button className="create-poll-btn" onClick={handleSubmit}><FaUpload /> Upload</button>
                                }
                              </div>
                            </div>
                            {data.length > 0 && (
                              <div>
                                <div className="mt-5 mb-2">
                                  <h3 className="mb-3 fs-2">Data Preview</h3>
                                  <div>
                                    <table className="table table-hover">
                                      <thead>
                                        <tr>
                                          <th>Question Text</th>
                                          <th>Difficulty</th>
                                          <th>Quiz Theme ID</th>
                                          <th>Quiz Subcategory ID</th>
                                          <th>Options</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {paginatedData.map((row, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>{row.text}</td>
                                              <td>{row.difficulty}</td>
                                              <td>{row.quizeThemeId}</td>
                                              <td>{row.quizeSubcategoryId}</td>
                                              <td>
                                                <table className="table table-hover">
                                                  <tbody>
                                                    <tr>
                                                      <td>{row.option1}</td>
                                                      <td>{row.correctOption == 1 ? 'Correct' : 'Incorrect'}</td>
                                                    </tr>
                                                    <tr>
                                                      <td>{row.option2}</td>
                                                      <td>{row.correctOption == 2 ? 'Correct' : 'Incorrect'}</td>
                                                    </tr>
                                                    <tr>
                                                      <td>{row.option3}</td>
                                                      <td>{row.correctOption == 3 ? 'Correct' : 'Incorrect'}</td>
                                                    </tr>
                                                    <tr>
                                                      <td>{row.option4}</td>
                                                      <td>{row.correctOption == 4 ? 'Correct' : 'Incorrect'}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="fs-5 text-gray-700">
                                        Showing <span className="fw-semibold">{dataStartCount}</span> to{" "}
                                        <span className="fw-semibold">
                                          {dataEndCount}{" "}
                                        </span>
                                        of <span className="fw-semibold">{data.length}</span> results
                                      </p>
                                      <ReactPaginate
                                        previousLabel={'Previous'}
                                        nextLabel={'Next'}
                                        pageCount={Math.ceil(data.length / itemsPerPage)}
                                        onPageChange={handlePageChange}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        breakLabel={'...'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                      />
                                    </div>

                                  </div>
                                </div>
                                <div className="d-flex fs-4 gap-4 align-items-center">
                                  {
                                    rowsUploaded.length > 0 &&
                                    <div className="d-flex gap-3 align-items-center">
                                      <button className="btn btn-outline-success py-2 d-flex justify-items-center align-items-center gap-2" onClick={downloadUploadedCSV}><FaCheck /> Download {numberofRows.uploaded} questions uploaded successfully</button>
                                      {/* <p><span className="fw-semibold">{rowsUploaded.length}</span> Rows uploaded successfully</p> */}
                                    </div>
                                  }
                                  {
                                    errors.length > 0 &&
                                    <div className="d-flex gap-3 align-items-center">
                                      <button className="btn btn-outline-danger py-2 d-flex justify-items-center align-items-center gap-2" onClick={downloadNotUploadedCSV}><FaTimes /> Download {numberofRows.notUploaded} questions not uploaded</button>
                                      {/* <p><span className="fw-semibold">{errors.length}</span> Rows not uploaded</p> */}
                                    </div>
                                  }
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </section>

        {/* <!-- End Page Title --> */}
      </main>

      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      {/* <footer id="footer" className="footer">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>PollPe</span>
          </strong>
          . All Rights Reserved
        </div>
      </footer> */}
    </div>
  );
};

export default CreateBatch;
