import axios from "axios";
import React, { useEffect, useState } from "react";
import { quizSettings } from "../Services/quiz.service";
import { updateQuizData } from "../Services/post.service";
import Loader from "../../Loader/Loader";

const QuizSettings = () => {
  const rewardValues = [1, 2, 3];

  const [showRewardTable, setShowRewardTable] = useState(true);
  const [showMessageSection, setShowMessageSection] = useState(false);
  const [defaultData, setDefaultData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rawData, setRawData] = useState({
    timeDelayBeforeNextQuestion: 10,
    timeAllotedPerQuestion: 5,
    successText: "",
    failedText: "",
  });

  const handleRewardTable = () => {
    setShowRewardTable((prevValue) => !prevValue);
    setShowMessageSection((prevValue) => !prevValue);
  };

  const token = localStorage.getItem("token");
  // const token = JSON.parse(localStorage.getItem("token"));

  useEffect( () => {
    setLoader(true)
    try {
       quizSettings()
      
      // axios
      //   .get(`https://qzpe-stag-panel.pollpe.com/api/v3/quizsetting`, {
      //     headers: {
      //       Authorization: "Bearer " + token,
      //     },
      //   })
        .then((response) => {
          setLoader(false)
          console.log(response.data.data.quizSetting);
          setDefaultData(response.data.data.quizSetting);
          setRawData({
            timeDelayBeforeNextQuestion:
              response.data.data.quizSetting.timeDelayBeforeNextQuestion,
            timeAllotedPerQuestion:
              response.data.data.quizSetting.timeAllotedPerQuestion,
            successText: response.data.data.quizSetting.successText,
            failedText: response.data.data.quizSetting.failedText,
          });
        });
    } catch (error) {
      setLoader(false)
      console.log(error);
    }
  }, [token]);

  console.log(defaultData.id);
  console.log(defaultData.successText);

  const handleChangeInRawData = (e) => {
    const { name, value } = e.target;
    setRawData({
      ...rawData,
      [name]: value,
    });
  };

  const handleUpdate = async() => {
    setLoader(true)
    try {
        await updateQuizData(rawData)
      // axios
      //   .put(`https://qzpe-stag-panel.pollpe.com/api/v3/quizsetting`, rawData, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: "Bearer " + token,
      //     },
      //   })
        .then((res) => {
          console.log(res.data)
          setLoader(false)
        });
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  };

  return (
    <div>
      {loader && (
        <Loader/>
        // <div className="fix-loader-center">
        //   <div className="set-loader-fixed"></div>
        //   <span className="second-loader"></span>
        // </div>
      )}
      <main id="main" className="main">
        <div className="quiz-settings">
          <div className="quiz-text">
            <h2>Quiz Settings</h2>
          </div>
          <div className="quiz-inputs">
            <div className="form-group quiz-input">
              <label>Time Allotted to Question: </label>
              <input
                type="number"
                name="timeDelayBeforeNextQuestion"
                className="form-control"
                placeholder="Enter Time Delay"
                value={rawData.timeDelayBeforeNextQuestion}
                onChange={handleChangeInRawData}
              />
            </div>
            <div className="form-group quiz-input">
              <label>Next Question Delay: </label>
              <input
                type="number"
                name="timeAllotedPerQuestion"
                placeholder="Enter Time Alloted"
                className="form-control"
                value={rawData.timeAllotedPerQuestion}
                onChange={handleChangeInRawData}
              />
            </div>
          </div>

          {/* <div className="quiz-toggle">
            <div>Reward based on score</div>
            <div className="toggle">
              <input
                type="checkbox"
                id="toggle-btn-required"
                checked={showRewardTable}
                onChange={handleRewardTable}
              />
              <label htmlFor="toggle-btn-required"></label>
            </div>
          </div>

          {showRewardTable && (
            <div className="table-responsive">
              <table className="table table-bordered table-striped table-quiz">
                <thead className="">
                  <tr className="quiz-thead">
                    <th className="col-1 quiz-thead-text text-center">Score</th>
                    <th className="col-1 quiz-thead-text text-center">Reward Value</th>
                  </tr>
                </thead>
                <tbody>
                  {rewardValues.map((value, index) => (
                    <tr key={index}>
                      <td className="text-center quiz-tdata-text">{index + 1}</td>
                      <td className="text-center quiz-tdata-text">{value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="quiz-toggle">
            <div>Random Rewards</div>
            <div className="toggle">
              <input
                type="checkbox"
                id="toggle-btn-required"
                checked={showMessageSection}
                onChange={handleRewardTable}
              />
              <label htmlFor="toggle-btn-required"></label>
            </div>
          </div>

          {showMessageSection && (
            <div className="quiz-inputs">
              <div className="form-group quiz-input">
                <label>Minimum Value</label>
                <input type="number" className="form-control" />
              </div>
              <div className="form-group quiz-input">
                <label>Maximum Value</label>
                <input type="number" className="form-control" />
              </div>
            </div>
          )} */}

          <div className="quiz-inputs">
            <div className="form-group quiz-input">
              <label>Success Text</label>
              <input
                type="text"
                name="successText"
                placeholder="Enter text when user success"
                className="form-control"
                value={rawData.successText}
                onChange={handleChangeInRawData}
              />
            </div>
            <div className="form-group quiz-input">
              <label>Failed Text</label>
              <input
                type="text"
                name="failedText"
                className="form-control"
                placeholder="Enter Text when user fail"
                value={rawData.failedText}
                onChange={handleChangeInRawData}
              />
            </div>
          </div>
          <div className="update-quizset">
            <button className="bn62" onClick={handleUpdate}>
              Update
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default QuizSettings;
