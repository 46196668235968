import React, { useEffect, useState } from "react";
import SingleWord from "../../assets/img/poll-selection-icons/single-word-icon.svg";
import SingleLine from "../../assets/img/poll-selection-icons/single-line-icon.svg";
import DateIcon from "../../assets/img/poll-selection-icons/date-icon.svg";
import TimeIcon from "../../assets/img/poll-selection-icons/time-icon.svg";
import DateTimeIcon from "../../assets/img/poll-selection-icons/date-time-icon.svg";
import RatingIcon from "../../assets/img/poll-selection-icons/rating-icon.svg";
import MultiLine from "../../assets/img/poll-selection-icons/multi-line-icon.svg";
import axios from "axios";
import { updateOptions, updateQuestionDetails } from "../Services/post.service";

const QuestionTextModal = ({
  isOpen,
  onClose,
  handleUpdate,
  handleQuestion,
}) => {
  const { id, options, questionText, questionDifficulty, itemId } = handleUpdate;

  const [showDialogBox, setShowDialogBox] = useState(false);
  const [showPollNaming, setShowPollNaming] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [difficultyLevel, setDifficultyLevel] = useState(questionDifficulty);
  const [selectOption, setSelectOption] = useState(false);
  const [updateOption, setUpdateOption] = useState(
    options.map((option) => ({
      id: option.id,
      text: option.text,
      isCorrectOption: option.isCorrectOption,
    }))
  );
  const [updatedData, setUpdatedData] = useState({
    text: questionText || "",
    difficulty: difficultyLevel,
    // quizpeSubcategoryId: null,
  });
  console.log(handleQuestion)

  useEffect(() => {
    setUpdatedData((prevData) => ({
      ...prevData,
      difficulty: +difficultyLevel,
    }));
  }, [difficultyLevel]);

  console.log(handleUpdate);
  console.log(options.id);

  const handleInputChange = (event) => {
    const { value, name } = event.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(event);
  };

  console.log(updatedData);

  console.log(updateOption);

  const handleOptionUpdate = async (optionid, data) => {
    // setShowLoader(true)
    await updateOptions(optionid,data)
    // axios
    //   .put(
    //     `https://qzpe-stag-panel.pollpe.com/api/v3/question/option/${optionid}`,
    //     data,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + token,
    //       },
    //     }
    //   )
      .then((response) => {
        console.log("Option updated successfully", response.data);
        // setShowLoader(false)
      })
      .catch((error) => {
        console.log("Error updating option", error);
        // setShowLoader(false)
      });
  };

  const handleOptionChange = (event, index) => {
    const { name, value, type, checked } = event.target;

    setUpdateOption((prevState) => {
      const updatedOptionsCopy = [...prevState];
      if (type === "text") {
        updatedOptionsCopy[index].text = value;
      }

      if (type === "checkbox") {
        updatedOptionsCopy.forEach((option, i) => {
          if (i === index && checked) {
            option.isCorrectOption = checked;
          } else {
            option.isCorrectOption = false;
          }
        });
      }

      // const option = updatedOptionsCopy[0];
      // const option2 = updatedOptionsCopy[1];
      // const option3 = updatedOptionsCopy[2];
      // const option4 = updatedOptionsCopy[3];

      updatedOptionsCopy.forEach((option) => {
        handleOptionUpdate(option.id, option);
      });

      // handleOptionUpdate(option.id, option);
      // handleOptionUpdate(option2.id, option2);
      // handleOptionUpdate(option3.id, option3);
      // handleOptionUpdate(option4.id, option4);

      console.log(updatedOptionsCopy);
      // console.log(option4);
      // console.log(option2);
      // console.log(option3);

      return updatedOptionsCopy;
    });
  };
  const token = localStorage.getItem("token")
  // const token = JSON.parse(localStorage.getItem("token"));

  const response = async () => {
    setShowLoader(true)
    try {

      await updateQuestionDetails(id, updatedData)
      // axios
        // .put(`https://qzpe-stag-panel.pollpe.com/api/v3/question/${id}`, updatedData, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: "Bearer " + token,
      //     },
      //   })
        .then((res) => {
          console.log(res.data);
          handleClose();
          handleQuestion(itemId);
          setSelectOption(true);
          setShowLoader(false);
        });
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };

  // const responseOption = updateOption.map((option) => {
  //   return axios.put(
  //     `https://qzpe-stag-panel.pollpe.com/api/v3/question/option/${option.id}`,
  //     option,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       },
  //     }
  //     )
  //   // );
  // }
  // );

  // useEffect(() => {
  //   Promise.all(responseOption)
  //     .then((response) => {
  //       console.log("Done successfully", response.data);
  //       handleClose();
  //       handleQuestion();
  //     })
  //     .catch((error) => console.log(error));
  // }, []);

  // useEffect(()=>{
  //   updateOption.forEach((option) => {
  //     responseOption(option);
  //   }, []);
  // },[])
  // .then((response) => {
  //   console.log(response.data);
  //   handleClose();
  //   handleQuestion();
  // });
  // } catch (error) {
  //   console.log(error);
  // }
  const updateTheData = () => {
    setShowLoader(true);

    response();
    // responseOption();
  };

  console.log(updateOption.id);

  // useEffect(()=>{

  //   updateOption.map((option)=>{
  //     responseOption(option)
  //     // console.log(option.id)
  //   })
  //   },[updateOption])

  // useEffect(()=>{
  //   updateTheOptions()
  // },[updateOption,])

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div id="create-poll-modal">
      <div
        className={`modal modal-create-poll ${isOpen ? "show" : ""} fade`}
        id="createPoll"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="createPollLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          {showPollNaming && (
            <div
              className={`modal-content ${
                showPollNaming ? "poll-naming" : "hidden"
              }`}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="createPollLabel">
                  Update <span>Question</span>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                  aria-label="Close"
                ></button>
              </div>
              {/* {selectOption ? (
                <div className="modal-body">
                  <div className="first-field mb-4">
                    <label htmlFor="poll-name" className="form-label">
                      Select Option
                    </label>
                    {updateOption.map((option, index) => (
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name={`quizpeSubCategory${index}`}
                          value={option.text}
                          onChange={(e) => handleOptionChange(e, index)}
                          aria-label={`Option ${index + 1} Text`}
                        />
                        <span className="question-opt-checkbox">
                          <input
                            className={`option-checkbox`}
                            type="checkbox"
                            name="isCorrectOption"
                            checked={option.isCorrectOption}
                            onChange={(e) => handleOptionChange(e, index)}
                            aria-label={`Option ${index + 1} Correct`}
                          ></input>
                        </span>
                      </div>
                    ))}
                  </div>

                  <div className="flex-icon gap-4">
                      <div className="modal-footer">
                        {showLoader ? (
                          <span className="loader"></span>
                        ) : (
                          <button
                            type="submit"
                            className="create-poll-btn"
                            id="poll-naming-continue"
                            onClick={updateTheOptions}
                          >
                            Update
                          </button>
                        )}
                      </div>
                  </div>
                </div>
              ) : ( */}
              <div>
                <div className="modal-body">
                  <div className="first-field mb-4">
                    <label htmlFor="poll-name" className="form-label">
                      Update Quiz
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Question Text"
                        name="text"
                        value={updatedData.text}
                        onChange={handleInputChange}
                        aria-label="Poll name"
                      />
                    </div>
                  </div>
                  <div className="flex-icon gap-4">
                    <div className="first-field mb-4">
                      <label htmlFor="poll-name" className="form-label">
                        Update the Difficulty
                      </label>
                      <div className="input-group mb-3">
                        <select
                          className="form-select"
                          value={difficultyLevel}
                          onChange={(e) => setDifficultyLevel(e.target.value)}
                          name="difficulty"
                          aria-label="Poll name"
                        >
                          <option value={1}>Easy</option>
                          <option value={2}>Medium</option>
                          <option value={3}>High</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="first-field mb-4">
                    <label htmlFor="poll-name" className="form-label">
                      Select Option
                    </label>
                    {updateOption.map((option, index) => (
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name={`quizpeSubCategory${index}`}
                          value={option.text}
                          onChange={(e) => handleOptionChange(e, index)}
                          aria-label={`Option ${index + 1} Text`}
                        />
                        <span className="question-opt-checkbox">
                          <input
                            className={`option-checkbox`}
                            type="checkbox"
                            name="isCorrectOption"
                            checked={option.isCorrectOption}
                            onChange={(e) => handleOptionChange(e, index)}
                            aria-label={`Option ${index + 1} Correct`}
                          ></input>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="modal-footer">
                  {showLoader ? (
                    <span className="loader"></span>
                  ) : (
                    <button
                      type="submit"
                      className="create-poll-btn"
                      id="poll-naming-continue"
                      onClick={updateTheData}
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
              {/* )} */}
            </div>
          )}

          {/* <!-- Poll Type Selection on Continue --> */}
          {showDialogBox && (
            <div
              className={`modal-content ${
                showDialogBox ? "poll-selection" : "hidden"
              }`}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="createPollLabel">
                  Select the <span>Poll Type</span>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {/* <!-- Selection Polls --> */}
                <div className="poll-types seletion-poll-type">
                  <p>Selection Poll</p>
                </div>

                {/* <!-- Text Polls --> */}
                <div className="poll-types text-poll-type">
                  <p>Text Poll</p>
                  <div className="poll-type">
                    <div className="icon">
                      <img
                        src={SingleWord}
                        alt="single-word-icon"
                        className="img-fluid"
                      />
                    </div>
                    <span>Single Word</span>
                  </div>
                  <div className="poll-type">
                    <div className="icon">
                      <img
                        src={SingleLine}
                        alt="single-line-icon"
                        className="img-fluid"
                      />
                    </div>
                    <span>Single Line</span>
                  </div>
                  <div className="poll-type">
                    <div className="icon">
                      <img
                        src={MultiLine}
                        alt="multi-line-icon"
                        className="img-fluid"
                      />
                    </div>
                    <span>Multi Line</span>
                  </div>
                </div>

                {/* <!-- Other Polls --> */}
                <div className="poll-types other-poll-type">
                  <p>Other</p>
                  <div className="poll-type">
                    <div className="icon">
                      <img
                        src={DateIcon}
                        alt="date-icon"
                        className="img-fluid"
                      />
                    </div>
                    <span>Date</span>
                  </div>
                  <div className="poll-type">
                    <div className="icon">
                      <img
                        src={TimeIcon}
                        alt="time-icon"
                        className="img-fluid"
                      />
                    </div>
                    <span>Time</span>
                  </div>
                  <div className="poll-type">
                    <div className="icon">
                      <img
                        src={DateTimeIcon}
                        alt="date-time-icon"
                        className="img-fluid"
                      />
                    </div>
                    <span>Date & Time</span>
                  </div>
                  <div className="poll-type">
                    <div className="icon">
                      <img
                        src={RatingIcon}
                        alt="rating-icon "
                        className="img-fluid"
                      />
                    </div>
                    <span>Rating</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionTextModal;
