import axios from "axios";
import { authapi } from "../components/Services/auth.service";


export const signInWithPassword = (details, callback) => {
  return async (dispatch) => {
      try {
        const response = await authapi(details.username,details.password)
    //     const response = await axios.post(
    //     `${BASE_URL}oauth2/login`,
    //     {
    //       username: details.username,
    //       password: details.password,
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );

      console.log(response.data.data.authToken);
      console.log(response.data);

      localStorage.setItem("token", response.data.data.authToken);
      //     // sessionStorage.setItem('token', response.data.data)
      // }
      // console.log(response)
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: response.data.data.authToken,
      });

      return callback(response.data);
    } catch (error) {
      console.log("error :", error);
      if (error.response && error.response.status === 401) {
        console.log("error in Auth comp")
        // logout(); // Call your logout function
        // window.location.href = '/login'; // Redirect to the login page
      } else {
        console.log("error :", error);
        return callback({
          error: true,
          response: error.response,
        });
      }
    }
  };
};

// export const signInWithGoogle = (details, callback) => {
//   return async (dispatch) => {
//       try {
//         const response = await authapi(details.username,details.password)
//       console.log(response.data.data.authToken);
//       console.log(response.data);

//       localStorage.setItem("token", response.data.data.authToken);
//       //     // sessionStorage.setItem('token', response.data.data)
//       // }
//       // console.log(response)
//       dispatch({
//         type: "LOGIN_SUCCESS",
//         payload: response.data.data.authToken,
//       });

//       return callback(response.data);
//     } catch (error) {
//       console.log("error :", error);
//       if (error.response && error.response.status === 401) {
//         console.log("error in Auth comp")
//         // logout(); // Call your logout function
//         // window.location.href = '/login'; // Redirect to the login page
//       } else {
//         console.log("error :", error);
//         return callback({
//           error: true,
//           response: error.response,
//         });
//       }
//     }
//   };
// };

export const logout = () => {
  localStorage.removeItem("token");
  // localStorage.removeItem("email");
//   window.location.href = "/login";
  return {
    type: "LOGOUT",
  };
  // return async (dispatch) => {
  // try{
  //     await axios.get(`${BASE_URL}/oauth2/logout`)
  // }
  // catch(error){
  //     console.log(error)
  // }

  // }
};
