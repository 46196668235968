import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

console.log(process.env.REACT_APP_BASE_URL);

// console.log(${BASE_URL})

// const token = localStorage.getItem('token')
// console.log(token)

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    console.log(token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // const original = error.config;

    if (error.response && error.response.status === 401) {
      // original.retry = true;
      localStorage.removeItem("token");
      window.location.href = "/login";

      // dispatch(logout())
    }
    return Promise.reject(error);
  }
);

const logout = () => {
  localStorage.removeItem("token");
  window.location.href = "/login";
};

export const getQuizData = async () => {
  try {
    const response = await axiosInstance.get("/theme/getall");
    if (response.status === 401) {
      logout();
      return null;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchCategories = async (id) => {
  try {
    const response = await axiosInstance.get(`/subcategory/f/${id}`);
    if (response.status === 401) {
      logout();
      return null;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteSubCategorySpecificId = async (id) => {
  try {
    const response = await axiosInstance.delete(`/subcategory/d/${id}`);
    if (response.status === 401) {
      logout();
      return null;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteSpecificTheme = async (id) => {
  try {
    const response = await axiosInstance.delete(`/theme/${id}`);
    if (response.status === 401) {
      logout();
      return null;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchQuestionsOfSpecificTheme = async (id) => {
  try {
    const response = axiosInstance.get(`/question/theme/${id}`);
    if (response.status === 401) {
      logout();
      return null;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteSpecificQuestion = async (id) => {
  try {
    const response = axiosInstance.delete(`/question/${id}`);
    if (response.status === 401) {
      logout();
      return null;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const quizSettings = async () => {
  try {
    const response = axiosInstance.get("/quizsetting");
    if (response.status === 401) {
      logout();
      return null;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAnalyticsDat = async () => {
  try {
    const response = axiosInstance.get("/basic_analytics");
    if (response.status === 401) {
      logout();
      return null;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const subCategoryData = async (dateFormat) => {
  try {
    const response = axiosInstance.get(`/subcategory`);
    // const response = axiosInstance.get('/leaderboard?date=2023-11-08')
    if (response.status === 401) {
      logout();
      return null;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const details = async (dateFormat) => {
  try {
    const response = axiosInstance.get(`/leaderboard?date=${dateFormat}`);
    // const response = axiosInstance.get('/leaderboard?date=2023-11-08')
    if (response.status === 401) {
      logout();
      return null;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
