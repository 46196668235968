import React, { useState } from "react";
import SingleWord from "../../assets/img/poll-selection-icons/single-word-icon.svg";
import SingleLine from "../../assets/img/poll-selection-icons/single-line-icon.svg";
import DateIcon from "../../assets/img/poll-selection-icons/date-icon.svg";
import TimeIcon from "../../assets/img/poll-selection-icons/time-icon.svg";
import DateTimeIcon from "../../assets/img/poll-selection-icons/date-time-icon.svg";
import RatingIcon from "../../assets/img/poll-selection-icons/rating-icon.svg";
import MultiLine from "../../assets/img/poll-selection-icons/multi-line-icon.svg";
import axios from "axios";
import { addSubCategory } from "../Services/post.service";

const CategoryModal = ({
  isOpen,
  onClose,
  selected,
  handleUpdate,
  pollAllData,
  updateCategoryModal,
}) => {
  const tagSuggestions = [
    "poll Questions",
    "Simple",
    "Multiple",
    "Dropdown",
    "Yes/No",
  ];

  const [showPollNaming, setShowPollNaming] = useState(true);
  const [tags, setTags] = useState([]);
  const [loader, setLoader] = useState(false);
  // const[updatedData, setUpdatedData] = useState([{
  //   tag: ''
  // }]);

  const [modalFormData, setModalFormData] = useState([
    {
      tag: "",
    },
  ]);

  const handleInputChange = (event) => {
    const { value, files, name } = event.target;

    setModalFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(event);
  };
  // console.log(updatedData.tag)

  console.log(updateCategoryModal);

  // console.log(updatedData)

  const updateTheData = async () => {
    setLoader(true);
    try {
      // const token = localStorage.getItem('token')
      // const token = JSON.parse(localStorage.getItem('token'))

      // const body = {
      //   quizpeThemeId: updateCategoryModal,
      //   subcategoryData: tags
      // }
      await addSubCategory(updateCategoryModal, tags)
        // console.log(formData)
        // await axios.post(
        // `https://qzpe-stag-panel.pollpe.com/api/v3/subcategory/add`,
        //   body,
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       "Authorization": "Bearer " + token,
        //     },
        //   }
        // )
        .then((response) => {
          console.log("Success", response.data);
          handleClose();
          pollAllData();
          setLoader(false);
        });
    } catch (error) {
      console.log("Network error", error);
      setLoader(false);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  // //Add Tags
  const addTags = (event) => {
    if (event.target.value !== "") {
      setTags([...tags, event.target.value]);
      selected([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  console.log(tags);

  //Remove Tags
  const removeTags = (indexTo) => {
    setTags(tags.filter((_, index) => index !== indexTo));
    selected(tags.filter((_, index) => index !== indexTo));
  };

  return (
    <div>
      {/* {loader && (
        <div className="fix-loader-center">
          <div className="set-loader-fixed"></div>
          <span className="second-loader"></span>
        </div>
      )} */}

      <div id="create-poll-modal">
        <div
          className={`modal modal-create-poll ${isOpen ? "show" : ""} fade`}
          id="createPoll"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="createPollLabel"
          // aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            {showPollNaming && (
              <div
                className={`modal-content ${
                  showPollNaming ? "poll-naming" : "hidden"
                }`}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="createPollLabel">
                    Add <span>Sub Categories</span>
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="second-field">
                    <label htmlFor="poll-category" className="form-label">
                      Select the Tag
                    </label>

                    <div className="category-box d-flex align-items-center mb-3">
                      {tags.map((tag, index) => {
                        return (
                          <div key={index} className="category-name">
                            <span>{tag}</span>
                            <div
                              className="cross-icon d-flex align-items-center justify-content-center"
                              onClick={() => removeTags(index)}
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </div>
                          </div>
                        );
                      })}

                      <div className="add-more-btn d-flex align-items-center gap-2">
                        <input
                          type="text"
                          list="tagSuggestions"
                          placeholder="Press Enter the Tags"
                          name="tag"
                          value={modalFormData.tag}
                          // onChange={handleInputChange}
                          onKeyUp={(e) =>
                            e.key === `Enter` ? addTags(e) : null
                          }
                        />
                        <datalist id="tagSuggestions">
                          {tagSuggestions.map((tag, index) => (
                            <option
                              key={index}
                              onKeyUp={(e) =>
                                e.key === `Enter` ? addTags(e) : null
                              }
                              value={tag}
                            />
                          ))}
                        </datalist>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {loader ? (
                    <span className="loader"></span>
                  ) : (
                    <button
                      type="submit"
                      className="create-poll-btn"
                      id="poll-naming-continue"
                      onClick={updateTheData}
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            )}

            {/* <!-- Poll Type Selection on Continue --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;
