import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL

const axiosInstance = axios.create({
    baseURL: BASE_URL
})

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers.Authorization = `Bearer  ${token}`
            config.headers["Content-Type"] = "multipart/form-data"
        }
        return config;
    },
    (error) => {
        Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },

    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token')
            window.location.href = "/login";

        }
        return Promise.reject(error)
    }
)

const logout = () => {
    localStorage.removeItem('token')
    window.localStorage.href = '/login'
}

export const addNewTheme = async(formData) => {
    try {
        const response = await axiosInstance.post('/theme/add', formData)
        if (response.status === 401) {
            logout()
            return null
        } else if (response.status !== 200) {
            return { error: 'Request Failed' }
        } else {
            return response
        }
    } catch (error) {
        console.log(error)
    }
}
export const updateThemeData = async(id, formData) => {
    console.log(formData)
    try {
        const response = await axiosInstance.put(`/theme/${id}`, formData)
        console.log(formData)
        if (response.status === 401) {
            logout()
            return null
        } else if (response.status !== 200) {
            return { error: 'Request Failed' }
        } else {
            return response
        }
    } catch (error) {
        console.log(error)
    }
}