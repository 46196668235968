import React, { Fragment, useEffect, useRef, useState } from "react";
import { LuFilter } from "react-icons/lu";
import Select, { useStateManager } from "react-select";
import { BsFillPlayFill, BsFillPauseFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import FilterModal from "../FilterModal/FilterModal";
import { BiEdit, BiSolidDownload } from "react-icons/bi";
import { GrFormAdd } from "react-icons/gr";
import { RiExpandUpDownFill } from "react-icons/ri";
import pauseButton from "../../assets/img/Pause Circle.svg";
import playButton from "../../assets/img/Play Circle.svg";
import axios from "axios";
import QuizModal from "../UI/QuizModal";
import { json } from "react-router-dom";
import EditModal from "../UI/EditModal";
import CategoryModal from "../UI/CategoryModal";
import { RxCross1 } from "react-icons/rx";
import { AiFillDelete } from "react-icons/ai";
import { deleteSpecificTheme, deleteSubCategorySpecificId, getQuizData } from "../Services/quiz.service";
import Loader from "../../Loader/Loader";
// import '../../css/scss/table.scss'

const AllPolls = () => {
  const [data, setData] = useState([]); // Dummy data for the table
  const [allData, setAllData] = useState([]); // Dummy data for the date range
  const [selectedValues, setSelectedValues] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [updateCategoryModal, setUpdateCategoryModal] = useState(null);
  const [deleteSubCategoryId, setDeleteSubCategoryId] = useState()
  const [updateModal, setUpdateModal] = useState({
    id: null,
    name: "",
    icon: null,
  });
  const [deleteCategory, setDeleteCategory] = useState([]);
  const [pollLabel, setPollLabel] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [pollData, setPollData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderOnSubCategory, setShowLoaderOnSubCategory] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteSubCategory, setDeleteSubCategory] = useState(false);
  const [deleteSpecificId, setDeleteSpecificId] = useState();
  const [successfullyDeleted, setSuccessfullyDeleted] = useState("");
  const [successfullyDeletedModal, setSuccessfullyDeletedModal] =
    useState(false);

  const updateDisplayData = (updatedItem) => {
    // Find the index of the item to be updated in the displayData array.
    const updatedIndex = displayData.findIndex(
      (item) => item.id === updatedItem.id
    );

    if (updatedIndex !== -1) {
      // If the item is found, update it in the displayData array.
      const updatedDisplayData = [...displayData];
      updatedDisplayData[updatedIndex] = updatedItem;
      setDisplayData(updatedDisplayData);
    }
  };

  // Poll Name
  const handlePollLabel = (quizdata) => {
    const updatedData = [...pollLabel, quizdata];
    setPollLabel(updatedData);
    console.log(updatedData);

    const uniqueIdentifier = Date.now();
    const localStorageKeyIdentifier = `pollLabel_${uniqueIdentifier}`;
    localStorage.setItem(
      localStorageKeyIdentifier,
      JSON.stringify(updatedData)
    );
  };

  const selected = (tags) => {
    setSelectedTags(tags);
  };

  const token = localStorage.getItem("token");
  // const token = JSON.parse(localStorage.getItem("token"));
  let cancelRequest = false;
  const pollAllData = async () => {
    setShowLoader(true);
    try {
      const response = await getQuizData();
      if (!cancelRequest) {
        setShowLoader(false);
        setPollData(response.data.data.feed);
        console.log(response.data.data.feed);
      }
    } catch (error) {
      if (!cancelRequest) {
        setShowLoader(false);
        console.error("Error fetching poll data:", error);
      }
    }
  };

  useEffect(() => {
    pollAllData();
  }, []);

  const handleDelete = async (id) => {
    setShowLoaderOnSubCategory(true);
    try {
      const response = await deleteSpecificTheme(deleteSpecificId) 
      // axios.delete(
      //   `https://qzpe-stag-panel.pollpe.com/api/v3/theme/${deleteSpecificId}`,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + token,
      //     },
      //   }
      // );
      if (response.status === 200) {
        console.log("Deleted Successfully", deleteSpecificId);
        setSuccessfullyDeleted(`Deleted Successfully # ${deleteSpecificId}`);
        setSuccessfullyDeletedModal(true);
        setShowLoaderOnSubCategory(false);
        const updatedPollData = pollData.filter(
          (poll) => poll.id !== deleteSpecificId
        );
        setPollData(updatedPollData);
        setDeleteModal(false);
      } else {
        // setShowLoader(false)
        console.log("some error occurred");
      }
    } catch (error) {
      setShowLoaderOnSubCategory(false);
      console.log("some error occurred", error);
    }
  };

  const handleDeleteSubCategory = async (id) => {
    setShowLoaderOnSubCategory(true);
    console.log(deleteSubCategoryId);
    try {
      const response = await deleteSubCategorySpecificId(deleteSubCategoryId)
      // const response = await axios.delete(
      //   `https://qzpe-stag-panel.pollpe.com/api/v3/subcategory/d/${id}`,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + token,
      //     },
      //   }
      // );
      if (response.status === 200) {
        console.log("Deleted subCategory successfully", deleteSubCategoryId);
        const updatedPollSubCategory = deleteCategory.filter(
          (subCategory) => subCategory.id !== deleteSubCategoryId
        );
        setDeleteCategory(updatedPollSubCategory);
        setShowLoaderOnSubCategory(false);
        pollAllData();
        setDeleteSubCategory(false)
      } else {
        // setShowLoader(false)
        console.log("Some error occured");
      }
    } catch (error) {
      setShowLoaderOnSubCategory(false);
      console.log("subCategory error in Poll", error);
    }
  };

  //Targeting Pagination

  // GO to next Page by clicking on next
  // const handleNextPage = () => {
  //   setCurrentPage((nextPage) => nextPage + 1);
  // };
  // // GO to previous Page by clicking on previous
  // const handlePreviousPage = () => {
  //   setCurrentPage((prevPage) => prevPage - 1);
  // };

  // const handlePageChange = (e) => {
  //   setItemsPerPage(e.target.value);
  //   setCurrentPage(1);
  // };

  // const filteredData = data.filter((item) =>
  //   item.title.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  // const pageNumber = [];
  // const totalPosts = filteredData.length;
  // // console.log(totalPosts);

  // const indexOfLastPage = currentPage * itemsPerPage;
  // const indexOfFirstPage = indexOfLastPage - itemsPerPage;
  // const currentItems = filteredData.slice(indexOfFirstPage, indexOfLastPage);

  // // console.log(Math.ceil(totalPosts / itemsPerPage));
  // for (let i = 1; i <= Math.ceil(totalPosts / itemsPerPage); i++) {
  //   pageNumber.push(i);
  // }

  // // console.log(pageNumber)

  // const paginate = (number) => {
  //   setCurrentPage(number);
  // };

  const handleCloseModal = () => {
    setShowFilterModal(false);
  };

  //I want to update the item.
  const handleEdit = (id, name, icon) => {
    setShowEditModal(true);
    setUpdateModal({ id, name, icon });
  };

  const handleModalOfCaegory = (id) => {
    console.log(id);
    setShowCategoryModal(true);
    setUpdateCategoryModal(id);
  };

  console.log(showCreateModal);

  const handleQuiz = () => {
    setShowCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    setShowCategoryModal(false);
  };
  const handleCloseUpdateModal = () => {
    setShowEditModal(false);
    setUpdateModal(null);
  };

  useEffect(() => {
    if (successfullyDeletedModal) {
      setTimeout(() => {
        setSuccessfullyDeletedModal(false);
      }, 3000);
    }
  }, [successfullyDeletedModal]);

  const removeTags = (indexTo) => {
    setSelectedTags(selectedTags.filter((_, index) => index !== indexTo));
  };

  // const handleDelete = (indexTo) => {
  //   const updatedData = pollLabel.filter((_, index) => index !== indexTo);
  //   setPollLabel(updatedData);

  //   const localStorageData = {};
  //   for (let i = 0; i < localStorage.length; i++) {
  //     const key = localStorage.key(i);
  //     if (key.startsWith("pollLabel_")) {
  //       const storedData = JSON.parse(localStorage.getItem(key));
  //       const updatedStoredData = storedData.filter(
  //         (_, index) => index !== indexTo
  //       );
  //       if (updatedStoredData.length > 0) {
  //         localStorageData[key] = JSON.stringify(updatedStoredData);
  //       } else {
  //         localStorage.removeItem(key);
  //       }
  //     }
  //   }
  //   localStorage.clear();
  //   Object.keys(localStorageData).forEach((key) => {
  //     localStorage.setItem(key, localStorageData[key]);
  //   });
  // };

  return (
    <div>
      {showLoader && (
        <Loader/>
        // <div className="fix-loader-center">
        //   <div className="set-loader-fixed"></div>
        //   <span className="second-loader"></span>
        // </div>
      )}
      {showLoaderOnSubCategory && (
        <Loader/>
        // <div className="fix-loader-center">
        //   <div className="set-loader-fixed"></div>
        //   <span className="second-loader"></span>
        // </div>
      )}
      {successfullyDeletedModal && (
        <div className="successfully-added">
          <span className="added">{successfullyDeleted}</span>
        </div>
      )}
      <div id="polls">
        {showCreateModal && (
          <QuizModal
            isOpen={showCreateModal}
            onClose={handleCloseCreateModal}
            handlePollLabel={handlePollLabel}
            pollAllData={pollAllData}
            pollData={pollData}
            // handleImagge={handleImage}
            selected={selected}
          />
        )}
        {showEditModal && (
          <EditModal
            isOpen={showEditModal}
            onClose={handleCloseUpdateModal}
            handleUpdate={updateModal}
            pollAllData={pollAllData}
            // handleImagge={handleImage}
            selected={selected}
            updatedDisplayData={updateDisplayData}
          />
        )}
        {showCategoryModal && (
          <CategoryModal
            isOpen={showCategoryModal}
            onClose={handleCloseCreateModal}
            handleUpdate={updateModal}
            pollAllData={pollAllData}
            updateCategoryModal={updateCategoryModal}
            // handleImagge={handleImage}
            selected={selected}
            updatedDisplayData={updateDisplayData}
          />
        )}

        <div className="col-12 mt-5">
          <div className="create-button">
            <button className="create-theme-button" onClick={handleQuiz}>
              + Create Theme
            </button>
          </div>

          <FilterModal
            data={data}
            allData={allData}
            setData={setData}
            isOpen={showFilterModal}
            onClose={handleCloseModal}
          />
          {/* Theme Section */}
          {pollData.map((item, index) => (
            <div className="card-for-themesection" key={index}>
              <div className="heading-section">
                <p>Theme</p>

                <div className="subcard-themesection">
                  <div className="flex-icon gap-4">
                    <h1>#{item.id}</h1>
                    <img
                      src={item.icon}
                      width="100"
                      alt={item.name}
                      className="file-theme"
                    />
                    <p>{item.name}</p>
                  </div>
                </div>
              </div>
              <div className="heading-section" id="createPoll">
                <p>Category</p>

                <div className="subcard-label-themesection">
                  <div
                    className="category-box sub-categorybox gap-10 d-flex align-items-center mb-3"
                    style={{ gap: "1.5rem" }}
                  >
                    {item.quizpe_subcategories.map((category) => (
                      <div className="category-name" key={category.id}>
                        <span className="wrap-category-name">
                          {category.name}
                        </span>
                        <div
                          className="crossed-icon d-flex align-items-center justify-content-center"
                          onClick={() => {
                            setDeleteSubCategory(true);
                            // handleDeleteSubCategory(category.id);
                            setDeleteSubCategoryId(category.id)
                          }}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                      </div>
                    ))}
                    <div
                      className="categories-add-in"
                      onClick={() => handleModalOfCaegory(item.id)}
                    >
                      <GrFormAdd style={{ color: "white" }} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-delete">
                <p>Operations</p>
                <div className="center-button">
                  <BiEdit
                    className="delete-button"
                    onClick={() => handleEdit(item.id, item.name, item.icon)}
                  />
                  <MdDelete
                    className="delete-button"
                    onClick={() => {
                      setDeleteModal(true);
                      setDeleteSpecificId(item.id);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Modal To Delete Item */}

        {deleteSubCategory && (
          <div className="modal-to-delete-item">
            <div className="modal-delete">
              <span className="modal-icon">
                <button onClick={() => setDeleteSubCategory(false)}>
                  <RxCross1 />
                </button>
              </span>

              <div className="all-deletecontent-center">
                <div className="a-border">
                  <div className="modal-delete-icon">
                    {/* <AiFillDelete /> */}
                    <RxCross1 style={{ fontWeight: 900 }} />
                  </div>
                </div>
                <h2>Are you Sure?</h2>
                <p>
                  Do you really wants to delete this subCategory? This process
                  cannot be undone.
                </p>
                <hr style={{ backgroundColor: "black" }} />
                <div className="cancel-delete">
                  <button
                    className="cancel-spec"
                    onClick={() => setDeleteSubCategory(false)}
                  >
                    Cancel
                  </button>
                  <button className="delete-specific" onClick={handleDeleteSubCategory}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {deleteModal && (
          <div className="modal-to-delete-item">
            <div className="modal-delete">
              <span className="modal-icon">
                <button onClick={() => setDeleteModal(false)}>
                  <RxCross1 />
                </button>
              </span>

              <div className="all-deletecontent-center">
                <div className="a-border">
                  <div className="modal-delete-icon">
                    {/* <AiFillDelete /> */}
                    <RxCross1 style={{ fontWeight: 900 }} />
                  </div>
                </div>
                <h2>Are you Sure?</h2>
                <p>
                  Do you really wants to delete the this theme? This process
                  cannot be undone.
                </p>
                <hr style={{ backgroundColor: "black" }} />
                <div className="cancel-delete">
                  <button
                    className="cancel-spec"
                    onClick={() => setDeleteModal(false)}
                  >
                    Cancel
                  </button>
                  <button className="delete-specific" onClick={handleDelete}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="center-container">
          {selectedValues > 1 && (
            <div className="modal-container">
              <p>{selectedValues} tasks selected</p>
              <p>
                <BiSolidDownload />
              </p>
              <p>
                <BsFillPlayFill />
              </p>
              <p>
                <BsFillPauseFill />
              </p>
            </div>
          )}
          {/* <EditModal
          isOpen={showEditModal}
          onClose={handleCloseCreateModal}
          handlePollLabel={handlePollLabel}
          // handleImagge={handleImage}
          selected={selected}
        />
        <QuizModal
          isOpen={showCreateModal}
          onClose={handleCloseCreateModal}
          handlePollLabel={handlePollLabel}
          // handleImagge={handleImage}
          selected={selected}
        /> */}
        </div>
      </div>
    </div>
  );
};

export default AllPolls;
