import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { updateThemeData } from "../Services/theme.service";

const EditModal = ({
  isOpen,
  onClose,
  selected,
  handleUpdate,
  pollAllData,
}) => {
  const { id, name } = handleUpdate;

  const [showPollNaming] = useState(true);
  const [tags] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [updatedData, setUpdatedData] = useState({
    pollName: name,
    selectedFiles: null,
  });
  const [displayMessageOnSizeFile, setDisplayMessageOnSizeFile] = useState("");

  const [modalFormData] = useState([
    {
      pollName: "",
      selectedFiles: null,
    },
  ]);

  const handleInputChange = (event) => {
    const { value, files, name } = event.target;

    if (name === "selectedFiles" && files && files[0]) {
      const maxFile = 20 * 1024;
      const file = files[0];
      if (file.size >= maxFile) {
        setDisplayMessageOnSizeFile("*File size must be less than 20 kb*");
        event.target.value = "";
        return;
      } else {
        setDisplayMessageOnSizeFile("");
      }
    }

    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
    console.log(event);
  };
  console.log(modalFormData.tag);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  console.log(updatedData);

  const updateTheData = async () => {
    setShowLoader(true)
    try {
      const token = localStorage.getItem("token");
      // const token = JSON.parse(localStorage.getItem('token'))

      const formData = new FormData();
      formData.append("name", updatedData.pollName);
      formData.append("image", updatedData.selectedFiles);

      await updateThemeData(id, formData)
        // await axios.put(
        //   `https://qzpe-stag-panel.pollpe.com/api/v3/theme/${id}`,
        //   formData,
        //   {
        //     headers: {
        //       "Content-Type": "multipart/form-data",
        //       "Authorization": "Bearer " + token,
        //     },
        //   }
        // )
        .then((response) => {
          console.log("Success", response.data);
          handleClose();
          pollAllData();
          setShowLoader(false);
        });
    } catch (error) {
      console.log("Network error", error);
      setShowLoader(false)
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  // console.log(tags);

  return (
    <div id="create-poll-modal">
      <div
        className={`modal modal-create-poll ${isOpen ? "show" : ""} fade`}
        id="createPoll"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="createPollLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          {showPollNaming && (
            <div
              className={`modal-content ${
                showPollNaming ? "poll-naming" : "hidden"
              }`}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="createPollLabel">
                  Update the Particular<span> Theme Section </span>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="first-field mb-4">
                  <label htmlFor="poll-name" className="form-label">
                    Give it a Theme Name
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="pollName"
                      value={updatedData.pollName}
                      onChange={handleInputChange}
                      aria-label="Poll name"
                    />
                  </div>
                </div>
                <div className="flex-icon gap-4">
                  <div className="first-field mb-4">
                    <label htmlFor="poll-name" className="form-label">
                      Select the Theme Icon
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        className="form-control"
                        placeholder="Label"
                        accept="image/png"
                        name="selectedFiles"
                        onChange={handleInputChange}
                        aria-label="Poll name"
                      />
                    </div>
                    <div className="display-message-on-filesize">{displayMessageOnSizeFile}</div>
                  </div>
                  <div>
                    {selectedFile && (
                      <img
                        src={selectedFile}
                        alt="Icon"
                        className="file-theme"
                      ></img>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {showLoader ? (
                  <span className="loader"></span>
                ) : (
                  <button
                    type="submit"
                    className="create-poll-btn"
                    id="poll-naming-continue"
                    onClick={updateTheData}
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditModal;
