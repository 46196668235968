import React from 'react'
import { Player } from "@lottiefiles/react-lottie-player";

const Loader = () => {
  return (
    <div>
        <div className="fix-loader-center">
          <div className="set-loader-fixed"></div>
          <Player
            autoplay
            loop
            src="https://lottie.host/492f8f12-2b00-4ed4-8693-5e4511b54663/CNqBJMV5cy.json"
            style={{ height: "100px", width: "100px" }}
          />
            
          {/* <span className="second-loader"></span> */}
        </div>
    </div>
  )
}

export default Loader